
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import SearchBox from '@/components/SearchBox.vue'; 

type CollectionItem = { [key:string]: any } | string;
type LabelFunction = (item: CollectionItem) => string;
type ValueFunction = (item: CollectionItem) => any;

@Component({
  components: {
    SearchBox,
  }
})
export default class CustomGroupedCheckboxList extends Vue {  
  @Prop(Array) private collection!: CollectionItem[];
  @Prop() private generateLabelFunction?: LabelFunction;
  @Prop() private generateValueFunction?: ValueFunction;
  @Prop({ default: 'auto' }) private height!: string;
  @Prop({ default: true }) private showFilter!: boolean;
  @Prop({ default: () => []}) private searchKeys!: string[];
  @Prop({ default: true }) private showCount!: boolean;
  @Prop({ default: false }) private showSelectAll!: boolean;
  @Prop({ default: null }) private state!: boolean | null;
  @Prop() private value!: any;

  selectAllChecked = false;
  selectionValue: any[] = [];
  listedItens: CollectionItem[] = [];

  get uniqueId(): string {
    return '_' + Math.random().toString(36).substr(2, 9);
  }

  get allSelected(): boolean {
    return this.selectionValue.length === this.collection.length;
  }

  get noneSelected(): boolean {
    return this.selectionValue.length === 0;
  }

  onSearchFinished(result:CollectionItem[]): void {
    this.listedItens = result;
  }

  resetList(): void {
    this.listedItens = this.collection;
  }

  toggleAll(): void {
    this.selectionValue = this.allSelected ? [] : this.collection.map(item => this.generateValue(item));
  }

  generateLabel(item: CollectionItem): string {
    if (!this.generateLabelFunction) return JSON.stringify(item);
    return this.generateLabelFunction(item);
  }

  generateValue(item: CollectionItem): any {
    if (!this.generateValueFunction) return item;
    return this.generateValueFunction(item);
  }

  @Watch('selectionValue')
  onSelectionValueChange(): void {
    if (this.allSelected) this.selectAllChecked = true;
    this.$emit('input', this.selectionValue);    
  }

  @Watch('collection')
  onCollectionChanged(): void {
    this.resetList();
  }

  @Watch('value')
  onValueChanged(value:any): void {
    this.selectionValue = value;
  }

  mounted(): void {
    this.onValueChanged(this.value);
    this.resetList();
  }
}
