
import { Component, Prop, Vue } from 'vue-property-decorator';
import draggable from 'vuedraggable';
type CollectionItem = { [key:string]: any };
type LabelFunction = (item: CollectionItem) => string;

@Component({
  components: {
    draggable,
  }
})
export default class PillsList extends Vue {
  @Prop(Array) private collection!: CollectionItem[];
  @Prop() private generateLabelFunction?: LabelFunction;
  @Prop({ default: true }) private deletable!: boolean;
  @Prop({ default: false }) private selectable!: boolean;
  @Prop({ default: false }) private draggable!: boolean;
  @Prop({ default: false }) private tooltip!: boolean;

  activeItem: CollectionItem | null = null;

  uniqueIdPrefix = '';

  //todo: criar util ou usar lib para gerar uuid
  generateUniqueId(): string {
    return '_' + Math.random().toString(36).substr(2, 9);
  }

  generateLabel(item: CollectionItem): string {
    if (!this.generateLabelFunction) return JSON.stringify(item);
    return this.generateLabelFunction(item);
  }
  
  deleteItem(item: CollectionItem, index: number): void {
    this.$emit('itemDeleted', {item, index});
    this.collection.splice(index, 1);
  }

  selectItem(item: CollectionItem, index: number): void {
    if (!this.selectable) return;
    this.activeItem = item; 
    this.$emit('itemSelected', {item, index});
  }

  isActive(item: CollectionItem): boolean {
    return this.activeItem ? this.activeItem === item : false;
  }

  mounted(): void {
    this.uniqueIdPrefix = this.generateUniqueId();
  }
}
